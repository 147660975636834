
<template class="notifications">
  <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">
      <div class="d-flex align-center ">
        <!--        <v-avatar
          tile
          size="25"
          class="me-3"
        >
&lt;!&ndash;          <img
            class="primary&#45;&#45;text" style="color: #D23F57"
            src="@/assets/images/icons/comment.svg"
            alt=""
          >&ndash;&gt;

        </v-avatar>-->
        <img
          v-svg-inline
          class="icon me-3 primary--text"
          style="color: #D23F57"
          src="@/assets/images/icons/comment.svg"
          alt=""
        >
        <h2 class="mb-0">
          {{ $t('Notifications') }}<v-chip
            color="default"
            small
            class="ml-2"
          >
            {{ total }}
          </v-chip>
        </h2>
      </div>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('Recherche')"
        single-line
        hide-details
        outlined=""
        dense
      />
    </div>
    <v-row
      justify="center"
      no-gutters
    >
      <v-col cols="12">
        <v-row
          class="mb-4 "
          justify="center"
        >
          <v-col cols="12">
            <template>
              <base-card>
                <v-card-actions>
                  <v-btn
                    :loading="loading"
                    :disabled="!previous"
                    text
                    depressed
                    small
                    @click="loadPrevious"
                  >
                    <v-icon>mdi-chevron-left</v-icon>{{ $t("Plus récent") }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    :loading="loading"
                    :disabled="!next"
                    text
                    depressed
                    small
                    @click="loadNext"
                  >
                    {{ $t("Plus vieux") }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-divider />
                <v-list
                  :loading="loading"
                  dense
                  height="600"
                  max-height="600"

                  class="overflow-y-auto"
                >
                  <template v-for="(item, index) in items">
                    <v-list-item
                      :key="item.id"
                      dense
                      :loading="loading"
                      avatar
                      ripple
                      @click="navigateToPath(item.data)"
                    >
                      <v-list-item-avatar>
                        <v-img
                          :src="item.data.avatar || '/assets/images/avatars/1.png'"
                          lazy-src="/assets/images/avatars/1.png"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.data.subject || ' ' }}</v-list-item-title>
                        <v-list-item-subtitle v-html="shortHtml(item.data.body ||item.data.content || ' ',50)" />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text>
                          {{ item.human_created_at || formatDate(item.created_at) }}
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index + 1 < items.length"
                      :key="index"
                    />
                  </template>
                </v-list>
                <v-divider />
                <v-card-actions>
                  <v-btn
                    :loading="loading"
                    :disabled="!previous"
                    text
                    depressed
                    small
                    @click="loadPrevious"
                  >
                    <v-icon>mdi-chevron-left</v-icon>{{ $t("Plus récent") }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    :loading="loading"
                    :disabled="!next"
                    text
                    depressed
                    small
                    @click="loadNext"
                  >
                    {{ $t("Plus vieux") }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </base-card>
            </template>
          </v-col>
        </v-row>
      </v-col>

      <v-snackbar
        v-model="snackbar.active"
        :color="snackbar.color"
      >
        {{ snackbar.text }}
        <v-btn

          flat
          @click="snackbar.active = false"
        >
          {{ $t("Close") }}
        </v-btn>
      </v-snackbar>
    </v-row>
  </div>
</template>
<script>
  import moment from 'moment'

  export default {
    name: 'Notifications',
    components: {},
    props: {
      source: String,
      module: Object,

      modal: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: '/notifications',
      },
      select: {
        type: Boolean,
        default: false,
      },
      event: {
        type: Function,
        default: function (event, data) {
        },
      },
    },
    data: () => ({
      total: 0,
      previous: null,
      next: null,
      loading: false,
      pagination: { rowsPerPage: 10, page: 1 },
      fab: false,
      dialog: false,
      view_dialog: false,
      confirm: false,
      confirmObject: { action: '', text: '', active: false },
      snackbar: { active: false, text: '', color: 'primary' },
      search: '',
      showFirstLastPage: true,
      showCurrentPage: true,

      headers: [
        { text: 'Subject', value: 'data.headline' },
        { text: 'Date', value: 'created_at' },
        { text: 'Actions', sortable: false, value: 'action' },
      ],
      table_items: [
        'Validate', 'Suspend', 'Delete',
      ],

      selected: [],
      selectedBills: [],
      form: {},
      item: {},
      items: [],
      defaultItem: {},
      settings: { pagination: { page: 1 } },
      timeout: null,
      print_date: new Date().toISOString().substr(0, 7),
      print_modal: false,
      month_modal: false,
    }),
    computed: {
      params (nv) {
        return {
          query: this.search,
        }
      },
    },
    watch: {
      params: {
        handler () {
          // if (this.search.length > 2) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.items = []
            this.previous = null,
            this.next = null
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              this.previous = data.previous
              this.next = data.next
            })
          })

          // }
        },
        deep: true,
      },
    },
    mounted () {
      this.getData().then(data => {
        this.items = data.items
        this.total = data.total
        this.previous = data.previous
        this.next = data.next
      })
    },
    methods: {
      navigateToPath (item) {
        if (item.to) {
          this.$router.push(item.to || '')
        } else {
          if (item.action || item.link) {
            this.openLink(item.action || item.link, '_self')
          }
        }
      },
      eventListener (event, data) {
        switch (event) {
          case 'notification':
            this.pushItem(data)
            break
          default:
            (this.event)(event, data)
            break
        }
      },
      loadPrevious () {
        // this.url = this.previous;
        this.pagination.page -= 1
        this.getData().then(data => {
          this.items = data.items
          this.total = data.total
          this.previous = data.previous
          this.next = data.next
        })
      },
      loadNext () {
        // this.url = this.next;
        this.pagination.page += 1
        this.getData().then(data => {
          this.items = data.items
          this.total = data.total
          this.previous = data.previous
          this.next = data.next
        })
      },
      getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          var _this = this
          var orderBy = []
          /* if(this.pagination.sortBy)
                     for(var i=0;i<this.pagination.sortBy.length;i++){

                         orderBy.push({
                             column:this.pagination.sortBy[i],
                             direction:this.pagination.sortDesc[i]?"DESC":"ASC"
                         });
                     } */

          // get by search keyword
          axios.get(this.url, {
            params: {
              term: this.search,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy[0] : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
              page: this.pagination.page,
              per_page: this.pagination.itemsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const next = res.data.next_page_url
              const previous = res.data.prev_page_url

              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
                previous,
                next,
              })
            })
            .catch(err => console.log(err))
            .finally(() => _this.loading = false)
        })
      },

      newItem () {
        this.item = { type: '' }
        this.dialog = true
      },
      updateItems () {
        if (this.selected.length == 0) {
          this.toast(this.$t('Select items'), 'red')
          return false
        }
        this.update_dialog = true
      },
      markRead () {
        if (this.selected.length !== 0) {
          this.confirmObject.text = this.$t('Are you sure to mark as read')
          this.confirmObject.action = 'markread'
          this.confirm = true
        }
      },

      editItem (item) {
        this.item = item
        this.dialog = true
      },
      selectItem (item) {
        this.item = item;
        (this.event)('notification_selected', item);
        (this.event)('close', true)
      },
      viewItem (item) {
        this.item = item
        this.view_dialog = true
      },
      editPayment (item) {
        this.payment = item
      },
      deleteItems () {
        if (this.selected.length !== 0) {
          this.confirmObject.text = this.$t('Are you sure to delete')
          this.confirmObject.action = 'delete'
          this.confirm = true
        }
      },
      actionOnMultiple (action) {
        if (this.selected.length === 0) {
          this.snackbar.text = this.$t('Select items')
          this.snackbar.color = 'dark'
          this.snackbar.active = true
          return false
        }

        const _this = this
        const IDS = []
        for (let i = 0; i < this.selected.length; i++) {
          IDS.push(this.selected[i].id)
        }

        this.loading = true
        var obj = { ids: IDS }
        if (action === 'reject') {
          obj.comment = this.confirmObject.comment
        }
        axios.post('/notifications/action/' + action, obj)
          .then(response => {
            if (response.data) {
              const data = response.data

              if (action == 'delete') {
                for (let i = 0; i < IDS.length; i++) {
                  _this.items.splice(_this.getIndex(_this.items, 'id', IDS[i]), 1)
                }
              } else {
                for (let i = 0; i < data.length; i++) {
                  const object = data[i]
                  const index = _this.getIndex(_this.items, 'id', object.id)

                  if (index > -1) {
                    Vue.set(_this.items, index, object)
                  } else {
                    _this.items.unshift(object)
                  }
                }
              }
              _this.confirm = false
              _this.selected = []
              this.loading = false
            } else {
              this.snackbar.text = this.$t('An error occurred')
              this.snackbar.color = 'red'
              this.snackbar.active = true
              _this.confirm = false
              this.loading = false
            }
          })
      },

      /*
                format tweet created_at date to a nice string date
                 */
      /* formatDate (date_string) {
        var REFERENCE = moment(date_string)
        var TODAY = moment()
        var YESTERDAY = moment().subtract(1, 'day')
        /!*
            if the time is creater than a day show the month and day
             *!/
        if (moment(date_string).isSame(TODAY, 'h')) {
          return moment(date_string).fromNow()
        } else {
          if (moment(date_string).isSame(TODAY, 'd')) {
            return REFERENCE.format('H:m A')
          } else {
            return REFERENCE.format('MMM DD')
          }
        }

        return
      }, */
      /*
                method to get index of an item in an array
                using a field and a value of comparison
                 */
      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },
      /*
               method to get an item in an array
               using a field and a value of comparison
                */
      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },
      pushItem (item) {
        for (var i = 0; i < this.items.length; i++) {
          if (item.id === this.items[i].id) {
            Vue.set(this.items, i, item)
            return true
          }
        }
        this.items.unshift(item)

        this.dialog = false
      },
      toast (notification, color) {
        this.snackbar.text = notification
        this.snackbar.color = color
        this.snackbar.active = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.item = Object.assign({}, this.defaultItem)
        }, 300)
      },
    },
  }
</script>
